import "./blogStyles.css"
import React from "react"
import img from "../../images/Management.png"
import { Button, Image } from "@chakra-ui/react"
import { Link } from "gatsby"

const BlogsScroll = () => {
  return (
    <div style={{ display: "flex", overflowX: "scroll", gap: "25px" }}>
      <div
        style={{
          minWidth: "260px",
          maxWidth: "261px",
          backgroundColor: "white",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div className="blogs-container">
          <Image
            className="blogs-images"
            src={img}
            alt="blog-image"
            _hover={{ cursor: "pointer" }}
          />
          <div className="blogs-button">
            <Link to="/blogs/blog">
              <Button
                variant={"outline"}
                colorScheme={"purple"}
                size={"sm"}
                px="5"
                pt="2px"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ margin: "8px" }}>
          <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
            Blog Title
          </p>
          <p style={{ fontSize: 15, marginBottom: 0 }}>Date and Time</p>
        </div>
      </div>
      <div
        style={{
          minWidth: "260px",
          maxWidth: "261px",
          backgroundColor: "white",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div className="blogs-container">
          <Image
            className="blogs-images"
            src={img}
            alt="blog-image"
            _hover={{ cursor: "pointer" }}
          />
          <div className="blogs-button">
            <Link to="/blogs/blog">
              <Button
                variant={"outline"}
                colorScheme={"purple"}
                size={"sm"}
                px="5"
                pt="2px"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ margin: "8px" }}>
          <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
            Blog Title
          </p>
          <p style={{ fontSize: 15, marginBottom: 0 }}>Date and Time</p>
        </div>
      </div>
      <div
        style={{
          minWidth: "260px",
          maxWidth: "261px",
          backgroundColor: "white",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div className="blogs-container">
          <Image
            className="blogs-images"
            src={img}
            alt="blog-image"
            _hover={{ cursor: "pointer" }}
          />
          <div className="blogs-button">
            <Link to="/blogs/blog">
              <Button
                variant={"outline"}
                colorScheme={"purple"}
                size={"sm"}
                px="5"
                pt="2px"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ margin: "8px" }}>
          <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
            Blog Title
          </p>
          <p style={{ fontSize: 15, marginBottom: 0 }}>Date and Time</p>
        </div>
      </div>
      <div
        style={{
          minWidth: "260px",
          maxWidth: "261px",
          backgroundColor: "white",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div className="blogs-container">
          <Image
            className="blogs-images"
            src={img}
            alt="blog-image"
            _hover={{ cursor: "pointer" }}
          />
          <div className="blogs-button">
            <Link to="/blogs/blog">
              <Button
                variant={"outline"}
                colorScheme={"purple"}
                size={"sm"}
                px="5"
                pt="2px"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ margin: "8px" }}>
          <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
            Blog Title
          </p>
          <p style={{ fontSize: 15, marginBottom: 0 }}>Date and Time</p>
        </div>
      </div>
      <div
        style={{
          minWidth: "260px",
          maxWidth: "261px",
          backgroundColor: "white",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div className="blogs-container">
          <Image
            className="blogs-images"
            src={img}
            alt="blog-image"
            _hover={{ cursor: "pointer" }}
          />
          <div className="blogs-button">
            <Link to="/blogs/blog">
              <Button
                variant={"outline"}
                colorScheme={"purple"}
                size={"sm"}
                px="5"
                pt="2px"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ margin: "8px" }}>
          <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
            Blog Title
          </p>
          <p style={{ fontSize: 15, marginBottom: 0 }}>Date and Time</p>
        </div>
      </div>
      <div
        style={{
          minWidth: "260px",
          maxWidth: "261px",
          backgroundColor: "white",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div className="blogs-container">
          <Image
            className="blogs-images"
            src={img}
            alt="blog-image"
            _hover={{ cursor: "pointer" }}
          />
          <div className="blogs-button">
            <Link to="/blogs/blog">
              <Button
                variant={"outline"}
                colorScheme={"purple"}
                size={"sm"}
                px="5"
                pt="2px"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ margin: "8px" }}>
          <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
            Blog Title
          </p>
          <p style={{ fontSize: 15, marginBottom: 0 }}>Date and Time</p>
        </div>
      </div>
      <div
        style={{
          minWidth: "260px",
          maxWidth: "261px",
          backgroundColor: "white",
          overflow: "hidden",
          borderRadius: "8px",
          marginBottom: "10px",
        }}
      >
        <div className="blogs-container">
          <Image
            className="blogs-images"
            src={img}
            alt="blog-image"
            _hover={{ cursor: "pointer" }}
          />
          <div className="blogs-button">
            <Link to="/blogs/blog">
              <Button
                variant={"outline"}
                colorScheme={"purple"}
                size={"sm"}
                px="5"
                pt="2px"
              >
                View
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ margin: "8px" }}>
          <p style={{ fontWeight: 500, fontSize: 18, marginBottom: 0 }}>
            Blog Title
          </p>
          <p style={{ fontSize: 15, marginBottom: 0 }}>Date and Time</p>
        </div>
      </div>
    </div>
  )
}

export default BlogsScroll
