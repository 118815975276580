import "./blogStyles.css"
import React from "react"
import { Box, Grid } from "@chakra-ui/react"
import BlogsScroll from "./BlogsScroll"

const Blogs = () => {
  return (
    <Box bgColor={"#232323"}>
      <Box mx={{ lg: "2rem", base: "1rem" }} pt="20" pb="16">
        <Box fontSize={35} fontWeight={700} color="white">
          Blogs
        </Box>
        <Box color="white" fontSize={15} mt="4" mb="6">
          <Grid
            templateColumns={{ xl: "repeat(2, 1fr)", base: "repeat(1, 1fr)" }}
            gap={{ xl: 16, base: 2 }}
            color="white"
          >
            <Box>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat.
            </Box>
            <Box />
          </Grid>
        </Box>
        <Box
          as="button"
          bgColor={"#8326EA"}
          py={{ lg: "2", base: "1" }}
          px={{ lg: "6", base: "4" }}
          fontWeight={400}
          fontSize={{ xl: 19, lg: 18, sm: 17, base: 15 }}
          color="white"
          rounded={"full"}
          mb="8"
        >
          View more
        </Box>

        <BlogsScroll />
      </Box>
    </Box>
  )
}

export default Blogs
