import React from "react"
import { Box, Center } from "@chakra-ui/react"

const WantMoreInWela = () => {
  return (
    <Box my="16">
      <Center>
        <Box
          fontSize={{ xl: 40, lg: 35, sm: 30, base: 25 }}
          fontWeight={{ lg: 700, base: 600 }}
          pb="5"
        >
          Want more in wela?
        </Box>
      </Center>
      <Center>
        <Box
          textAlign={"center"}
          mx={{
            "2xl": "28rem",
            xl: "18rem",
            lg: "10rem",
            sm: "7rem",
            base: "2rem",
          }}
          fontSize={{ lg: 16, base: 14 }}
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit
        </Box>
      </Center>
      <Center>
        <Box
          as="button"
          bgColor={"#8326EA"}
          color="white"
          px="8"
          py="2"
          rounded={"full"}
          mt="8"
          fontWeight={600}
          fontSize={16}
        >
          FAQ
        </Box>
      </Center>
    </Box>
  )
}

export default WantMoreInWela
